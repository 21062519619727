// src/components/ActivityLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Material UI
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ActivityLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  // State
  const [logs, setLogs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  // Form fields
  const [name, setName] = useState('');
  const [activityDate, setActivityDate] = useState('');
  const [address, setAddress] = useState('');
  const [operatorName, setOperatorName] = useState('');
  const [bookingReference, setBookingReference] = useState('');
  const [notes, setNotes] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [pointsUsed, setPointsUsed] = useState('');

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const fetchLogs = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `https://api.triptoad.io/trips/${tripId}/activity`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLogs(res.data);
    } catch (error) {
      console.error('Error fetching activity logs:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // reset fields
    setName('');
    setActivityDate('');
    setAddress('');
    setOperatorName('');
    setBookingReference('');
    setNotes('');
    setPrice('');
    setCurrency('USD');
    setPointsUsed('');
  };

  const handleCreateLog = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `https://api.triptoad.io/trips/${tripId}/activity`,
        {
          name,
          activityDate,
          address,
          operatorName,
          bookingReference,
          notes,
          price,
          currency,
          pointsUsed: pointsUsed ? parseInt(pointsUsed) : null,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status === 201) {
        handleCloseDialog();
        fetchLogs();
      }
    } catch (error) {
      console.error('Error creating activity log:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          Activities for Trip {tripId}
        </Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Activity
        </Button>
      </Box>

      {/* List of existing activity logs */}
      <Grid container spacing={2}>
        {logs.map((log) => (
          <Grid item xs={12} sm={6} md={4} key={log.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{log.name}</Typography>
                <Typography variant="body2">
                  <strong>Date:</strong> {log.activityDate || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Address:</strong> {log.address || 'N/A'}
                </Typography>
                {/* Additional fields displayed here */}
              </CardContent>
              <CardActions>
                {/* Optionally, Edit/Delete buttons */}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Creating an Activity */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add Activity
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleCreateLog}
            id="create-activity-form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
          >
            <TextField
              label="Activity Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              label="Activity Date"
              type="datetime-local"
              value={activityDate}
              onChange={(e) => setActivityDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              label="Operator Name"
              value={operatorName}
              onChange={(e) => setOperatorName(e.target.value)}
            />
            <TextField
              label="Booking Reference"
              value={bookingReference}
              onChange={(e) => setBookingReference(e.target.value)}
            />
            <TextField
              label="Notes"
              multiline
              rows={2}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <TextField
              label="Currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
            <TextField
              label="Points Used"
              type="number"
              value={pointsUsed}
              onChange={(e) => setPointsUsed(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            form="create-activity-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ActivityLogManager;
