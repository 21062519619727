// src/components/SideNavLayout.js
import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const SideNavLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  // We destructure from Auth0:
  //  - isAuthenticated: whether the user is logged in
  //  - loginWithRedirect: a function to initiate the login flow
  //  - logout: to log out
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  // The drawer's nav items
  const drawer = (
    <div>
      <Toolbar />
      <List>
        {/* This link is always shown, whether logged in or out */}
        <ListItemButton component={Link} to="/my-trips">
          <ListItemText primary="My Trips" />
        </ListItemButton>

        {/* For convenience, let's also link to "Profile" — optional. */}
        <ListItemButton component={Link} to="/profile">
          <ListItemText primary="Profile" />
        </ListItemButton>

        {isAuthenticated ? (
          // If logged in, show "Logout"
          <ListItemButton onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        ) : (
          // Otherwise, show "Login"
          <ListItemButton onClick={loginWithRedirect}>
            <ListItemText primary="Login" />
          </ListItemButton>
        )}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/*
        If you truly want ZERO top bar, remove this entire <AppBar> block.
        If you want a hamburger button on mobile, keep it.
      */}
      <AppBar
        position="fixed"
        sx={{
          ml: { sm: `${drawerWidth}px` },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar>
          {/* hamburger menu icon (for mobile) */}
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* If you want a brand name up top */}
          <Typography variant="h6" noWrap component="div">
            TripToad
          </Typography>
        </Toolbar>
      </AppBar>

      {/* NAV DRAWER */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* Mobile: temporary drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // better performance on mobile
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        {/* Desktop: permanent drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* We push content down below the top AppBar */}
        <Toolbar />

        {/* Nested routes (from App.js) get rendered here */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default SideNavLayout;
