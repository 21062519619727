// src/components/TripLocationManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TripLocationManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  // Data
  const [locations, setLocations] = useState([]);

  // Modal open state
  const [openDialog, setOpenDialog] = useState(false);

  // Form fields
  const [locationName, setLocationName] = useState('');
  const [notes, setNotes] = useState('');
  const [order, setOrder] = useState('');

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const fetchLocations = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `https://api.triptoad.io/trips/${tripId}/locations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLocations(res.data);
    } catch (error) {
      console.error('Error fetching trip locations:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // reset form
    setLocationName('');
    setNotes('');
    setOrder('');
  };

  const handleCreateLocation = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `https://api.triptoad.io/trips/${tripId}/locations`,
        {
          locationName,
          notes,
          order: order ? parseInt(order) : null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 201) {
        handleCloseDialog();
        fetchLocations();
      }
    } catch (error) {
      console.error('Error creating location:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          Locations for Trip {tripId}
        </Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Location
        </Button>
      </Box>

      <Grid container spacing={2}>
        {locations.map((loc) => (
          <Grid item xs={12} sm={6} md={4} key={loc.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{loc.locationName}</Typography>
                <Typography variant="body2">
                  <strong>Notes:</strong> {loc.notes || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Order:</strong> {loc.order || 'N/A'}
                </Typography>
              </CardContent>
              <CardActions />
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for adding a new location */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add Location
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleCreateLocation}
            id="create-location-form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
          >
            <TextField
              label="Location Name"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              required
            />
            <TextField
              label="Notes"
              multiline
              rows={2}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              label="Order"
              type="number"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            form="create-location-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Location
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TripLocationManager;
