// src/components/LodgingLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LodgingLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  // Lodging logs data
  const [logs, setLogs] = useState([]);

  // Modal open state
  const [openDialog, setOpenDialog] = useState(false);

  // Form fields
  const [lodgingType, setLodgingType] = useState('Hotel');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [roomType, setRoomType] = useState('');
  const [notes, setNotes] = useState('');
  const [pricePerNight, setPricePerNight] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [pointsUsed, setPointsUsed] = useState('');

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const fetchLogs = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `https://api.triptoad.io/trips/${tripId}/lodging`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLogs(res.data);
    } catch (error) {
      console.error('Error fetching lodging logs:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Reset form
    setLodgingType('Hotel');
    setName('');
    setAddress('');
    setCheckInDate('');
    setCheckOutDate('');
    setConfirmationNumber('');
    setContactInfo('');
    setRoomType('');
    setNotes('');
    setPricePerNight('');
    setCurrency('USD');
    setPointsUsed('');
  };

  const handleCreateLodging = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `https://api.triptoad.io/trips/${tripId}/lodging`,
        {
          lodgingType,
          name,
          address,
          checkInDate,
          checkOutDate,
          confirmationNumber,
          contactInfo,
          roomType,
          notes,
          pricePerNight,
          currency,
          pointsUsed: pointsUsed ? parseInt(pointsUsed) : null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 201) {
        handleCloseDialog();
        fetchLogs();
      }
    } catch (error) {
      console.error('Error creating lodging log:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Title + "Add Lodging" Button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          Lodging for Trip {tripId}
        </Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Lodging
        </Button>
      </Box>

      {/* Existing lodging logs */}
      <Grid container spacing={2}>
        {logs.map((log) => (
          <Grid item xs={12} sm={6} md={4} key={log.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">
                  {log.lodgingType} - {log.name}
                </Typography>
                <Typography variant="body2">
                  <strong>Check In:</strong> {log.checkInDate || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Check Out:</strong> {log.checkOutDate || 'N/A'}
                </Typography>
                {/* Additional fields if desired */}
              </CardContent>
              <CardActions>{/* Optionally: Edit/Delete */}</CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for adding lodging */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add Lodging
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleCreateLodging}
            id="create-lodging-form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
          >
            <FormControl fullWidth>
              <InputLabel>Lodging Type</InputLabel>
              <Select
                value={lodgingType}
                label="Lodging Type"
                onChange={(e) => setLodgingType(e.target.value)}
              >
                <MenuItem value="Hotel">Hotel</MenuItem>
                <MenuItem value="Hostel">Hostel</MenuItem>
                <MenuItem value="FriendsFamily">Friends/Family</MenuItem>
                <MenuItem value="CruiseCabin">Cruise Cabin</MenuItem>
                <MenuItem value="Airbnb">Airbnb</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              label="Check In Date"
              type="datetime-local"
              value={checkInDate}
              onChange={(e) => setCheckInDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Check Out Date"
              type="datetime-local"
              value={checkOutDate}
              onChange={(e) => setCheckOutDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Confirmation Number"
              value={confirmationNumber}
              onChange={(e) => setConfirmationNumber(e.target.value)}
            />
            <TextField
              label="Contact Info"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
            />
            <TextField
              label="Room Type"
              value={roomType}
              onChange={(e) => setRoomType(e.target.value)}
            />
            <TextField
              label="Notes"
              multiline
              rows={2}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              label="Price per Night"
              type="number"
              value={pricePerNight}
              onChange={(e) => setPricePerNight(e.target.value)}
            />
            <TextField
              label="Currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
            <TextField
              label="Points Used"
              type="number"
              value={pointsUsed}
              onChange={(e) => setPointsUsed(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            form="create-lodging-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Lodging
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LodgingLogManager;
