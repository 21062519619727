// src/components/RestaurantLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const RestaurantLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  // Restaurant logs data
  const [logs, setLogs] = useState([]);

  // Modal open state
  const [openDialog, setOpenDialog] = useState(false);

  // Form fields
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [cuisineType, setCuisineType] = useState('');
  const [reservationDate, setReservationDate] = useState('');
  const [reservationNumber, setReservationNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [mealType, setMealType] = useState('Any');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [pointsUsed, setPointsUsed] = useState('');

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const fetchLogs = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `https://api.triptoad.io/trips/${tripId}/restaurant`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLogs(res.data);
    } catch (error) {
      console.error('Error fetching restaurant logs:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // reset fields
    setName('');
    setAddress('');
    setCuisineType('');
    setReservationDate('');
    setReservationNumber('');
    setNotes('');
    setContactInfo('');
    setMealType('Any');
    setPrice('');
    setCurrency('USD');
    setPointsUsed('');
  };

  const handleCreateRestaurant = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `https://api.triptoad.io/trips/${tripId}/restaurant`,
        {
          name,
          address,
          cuisineType,
          reservationDate,
          reservationNumber,
          notes,
          contactInfo,
          mealType,
          price,
          currency,
          pointsUsed: pointsUsed ? parseInt(pointsUsed) : null,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status === 201) {
        handleCloseDialog();
        fetchLogs();
      }
    } catch (error) {
      console.error('Error creating restaurant log:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Title + "Add Restaurant" Button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          Restaurants for Trip {tripId}
        </Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Restaurant
        </Button>
      </Box>

      {/* Existing restaurant logs */}
      <Grid container spacing={2}>
        {logs.map((log) => (
          <Grid item xs={12} sm={6} md={4} key={log.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{log.name}</Typography>
                <Typography variant="body2">
                  <strong>Cuisine:</strong> {log.cuisineType || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Reservation Date:</strong> {log.reservationDate || 'N/A'}
                </Typography>
                {/* Additional fields */}
              </CardContent>
              <CardActions />
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for adding restaurant log */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add Restaurant
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleCreateRestaurant}
            id="create-restaurant-form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
          >
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              label="Cuisine Type"
              value={cuisineType}
              onChange={(e) => setCuisineType(e.target.value)}
            />
            <TextField
              label="Reservation Date"
              type="datetime-local"
              value={reservationDate}
              onChange={(e) => setReservationDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Reservation Number"
              value={reservationNumber}
              onChange={(e) => setReservationNumber(e.target.value)}
            />
            <TextField
              label="Notes"
              multiline
              rows={2}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              label="Contact Info"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
            />
            <FormControl>
              <InputLabel>Meal Type</InputLabel>
              <Select
                value={mealType}
                label="Meal Type"
                onChange={(e) => setMealType(e.target.value)}
              >
                <MenuItem value="Breakfast">Breakfast</MenuItem>
                <MenuItem value="Lunch">Lunch</MenuItem>
                <MenuItem value="Dinner">Dinner</MenuItem>
                <MenuItem value="Any">Any</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <TextField
              label="Currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
            <TextField
              label="Points Used"
              type="number"
              value={pointsUsed}
              onChange={(e) => setPointsUsed(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            form="create-restaurant-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Restaurant
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RestaurantLogManager;
