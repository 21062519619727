// src/components/TripManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

// Material UI imports
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TripManager = () => {
  const { getAccessTokenSilently } = useAuth0();

  // State for existing trips
  const [trips, setTrips] = useState([]);

  // Dialog open state
  const [openDialog, setOpenDialog] = useState(false);

  // State for new trip form fields
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('Planning');
  const [visibility, setVisibility] = useState('private');
  const [currency, setCurrency] = useState('USD');
  const [estimatedCost, setEstimatedCost] = useState('');
  const [tags, setTags] = useState('');

  useEffect(() => {
    fetchTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTrips = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get('https://api.triptoad.io/trips', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTrips(res.data);
    } catch (error) {
      console.error('Error fetching trips:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // reset form fields if desired
    setName('');
    setDescription('');
    setStartDate('');
    setEndDate('');
    setStatus('Planning');
    setVisibility('private');
    setCurrency('USD');
    setEstimatedCost('');
    setTags('');
  };

  const handleCreateTrip = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        'https://api.triptoad.io/trips',
        {
          name,
          description,
          startDate,
          endDate,
          status,
          visibility,
          currency,
          estimatedCost,
          tags,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 201) {
        handleCloseDialog();
        fetchTrips(); 
      }
    } catch (error) {
      console.error('Error creating trip:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Title + Button to open modal */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">My Trips</Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Create New Trip
        </Button>
      </Box>

      {/* List of Trips */}
      <Grid container spacing={2}>
        {trips.map((trip) => (
          <Grid item xs={12} sm={6} md={4} key={trip.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {trip.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Description:</strong> {trip.description || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Dates:</strong> {trip.startDate} – {trip.endDate}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {trip.status}
                </Typography>
                <Typography variant="body2">
                  <strong>Visibility:</strong> {trip.visibility}
                </Typography>
                <Typography variant="body2">
                  <strong>Currency:</strong> {trip.currency}
                </Typography>
                <Typography variant="body2">
                  <strong>Estimated Cost:</strong> {trip.estimatedCost || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Tags:</strong> {trip.tags || 'N/A'}
                </Typography>
              </CardContent>

              <CardActions sx={{ mt: 'auto' }}>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/transportation`}
                >
                  Manage Transportation
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/lodging`}
                >
                  Manage Lodging
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/restaurant`}
                >
                  Manage Restaurants
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/activity`}
                >
                  Manage Activities
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/locations`}
                >
                  Manage Locations
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  href={`/trip/${trip.id}/share`}
                >
                  Share Trip
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal to create a new Trip */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Create New Trip
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleCreateTrip}
            id="create-trip-form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
          >
            <TextField
              required
              label="Trip Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              required
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              required
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="Planning">Planning</MenuItem>
                <MenuItem value="Planned">Planned</MenuItem>
                <MenuItem value="Complete">Complete</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Visibility</InputLabel>
              <Select
                value={visibility}
                label="Visibility"
                onChange={(e) => setVisibility(e.target.value)}
              >
                <MenuItem value="private">private</MenuItem>
                <MenuItem value="shared">shared</MenuItem>
                <MenuItem value="public">public</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
            <TextField
              label="Estimated Cost"
              type="number"
              value={estimatedCost}
              onChange={(e) => setEstimatedCost(e.target.value)}
            />
            <TextField
              label="Tags (comma separated)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            form="create-trip-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Create Trip
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TripManager;
