// src/App.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, useLocation } from 'react-router-dom';

import SideNavLayout from './components/SideNavLayout';
import Home from './components/Home';
import Profile from './components/Profile';
import Callback from './components/Callback';
import ProFeatureButton from './components/ProFeatureButton';
import TripManager from './components/TripManager';
import TransportationLogManager from './components/TransportationLogManager';
import LodgingLogManager from './components/LodgingLogManager';
import RestaurantLogManager from './components/RestaurantLogManager';
import ActivityLogManager from './components/ActivityLogManager';
import TripLocationManager from './components/TripLocationManager';
import UserTripManager from './components/UserTripManager';
import Loading from './components/Loading';

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(false);

  // Show a loading screen on route changes briefly
  useEffect(() => {
    setIsPageLoading(true);
    const timeout = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [location]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isPageLoading && <Loading />}
      {!isPageLoading && (
        <Routes>
          {/* 
            We wrap all "app" routes inside SideNavLayout so the side nav
            is visible on every route. If you only want side nav for certain 
            routes, adjust accordingly.
          */}
          <Route path="/" element={<SideNavLayout />}>
            {/* Home Page (public) */}
            <Route index element={<Home />} />

            {/* Auth0 Callback */}
            <Route path="callback" element={<Callback />} />

            {/* Profile (requires auth) */}
            <Route
              path="profile"
              element={isAuthenticated ? <Profile /> : <Home />}
            />

            {/* Pro Feature (requires auth) */}
            <Route
              path="pro-feature"
              element={isAuthenticated ? <ProFeatureButton /> : <Home />}
            />

            {/* My Trips (requires auth) */}
            <Route
              path="my-trips"
              element={isAuthenticated ? <TripManager /> : <Home />}
            />

            {/* Sub-routes for each category manager (requires auth) */}
            <Route
              path="trip/:tripId/transportation"
              element={isAuthenticated ? <TransportationLogManager /> : <Home />}
            />
            <Route
              path="trip/:tripId/lodging"
              element={isAuthenticated ? <LodgingLogManager /> : <Home />}
            />
            <Route
              path="trip/:tripId/restaurant"
              element={isAuthenticated ? <RestaurantLogManager /> : <Home />}
            />
            <Route
              path="trip/:tripId/activity"
              element={isAuthenticated ? <ActivityLogManager /> : <Home />}
            />
            <Route
              path="trip/:tripId/locations"
              element={isAuthenticated ? <TripLocationManager /> : <Home />}
            />
            <Route
              path="trip/:tripId/share"
              element={isAuthenticated ? <UserTripManager /> : <Home />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default App;
